<template>
    <div class="form-wrap">
      <form class="register" @submit.prevent="register">
          <p class="login-register">
              已经注册过了?
              <router-link class="router-link" :to="{name:'Login'}">登录</router-link>
          </p>
          <h2>向翟中豪的个人网站注册</h2>
          <div class="inputs">
                <div class="input">
                    <input type="text" placeholder="first name" v-model="firstName">
                    <user class="icon"/>
                </div>
                <div class="input">
                    <input type="text" placeholder="last name" v-model="lastName">
                    <user class="icon"/>
                </div>
                <div class="input">
                    <input type="text" placeholder="username" v-model="username">
                    <user class="icon"/>
                </div>                
                <div class="input">
                    <input type="text" placeholder="邮箱" v-model="email">
                    <email class="icon"/>
                </div>
                <div class="input">
                    <input type="password" placeholder="密码" v-model="password">
                    <password class="icon"/>
                </div>
                <div class="input">
                    <input type="password" placeholder="密码再次确认" v-model="passwordConfirm">
                    <password class="icon"/>
                </div>
                <div v-show="error" class="error">
                    {{this.errorMsg}}
                </div>
          </div> 
          <button>注册</button>
          <div class="angle"></div>        
      </form>
      <div class="background"></div>
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg"
import password from "../assets/Icons/lock-alt-solid.svg"
import user from "../assets/Icons/user-alt-light.svg"

export default {
    name:"Register",
    components:{
        email,
        password,
        user,
    },
    data(){
        return {
            firstName:"",
            lastName:"",
            username:"",
            email:"",
            password:"",
            passwordConfirm:"",
            error:null,
            errorMsg:"",
        }
    },
    methods:{
        async register(){
            //用户名、邮箱、密码和密码确认不能为空
            if(this.email == "" || this.password =="" || this.username == ""){
                this.error = true;
                this.errorMsg = "请填满所有的身份信息，谢谢!";
                return
            }

            //密码和密码确认要保持一致
            if(this.password != this.passwordConfirm){
                this.error = true;
                this.errorMsg = "密码不一致，请重新输入!";
                return
            }

            //向数据库注册用户信息
            this.error = false;
            this.errorMsg="";

            //注册
            var that = this
            await this.$store.dispatch('register',{
                email:that.email,
                username:that.username,
                password:that.password,
            })
            .then(()=>{
                
                this.error = false;
                this.errorMsg = "";
                that.$router.push({name:'Login'})
            })
            .catch((err)=>{
                this.error = true;
                this.errorMsg = err.response.data.message;
                console.log(this.errorMsg)
            })           
        },
    }

}
</script>

<style lang="scss" scoped>

</style>